import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select"]

  connect() {
    this.updateActionPath(); // Initialize the form action on page load
  }

  updateActionPath() {
    const selectedPharmacyId = this.selectTarget.value;
    if (selectedPharmacyId) {
      const pathSegments = window.location.pathname.split('/');
      const resource = pathSegments[3]; // Gets the part after the pharmacy ID (e.g., "agents", "orders")
      
      // Ensure the resource is defined
      if (resource) {
        this.element.action = `/pharmacies/${selectedPharmacyId}/${resource}`;
      } else {
        // If resource is not defined, default to a safe path or handle the case accordingly
        this.element.action = `/pharmacies/${selectedPharmacyId}`;
      }
    }
  }

  change() {
    this.updateActionPath();
    this.element.submit(); // Automatically submit the form when the selection changes
  }
}
